import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class UserModule extends VuexModule {
  public data: any = null;
  public loggedIn = false;

  @Mutation
  public setData(data: any) {
    this.data = data;
  }

  @Mutation
  public setLoggedIn(value: boolean) {
    this.loggedIn = value;
  }

  @Action
  public updateData(data: any) {
    this.context.commit("setLoggedIn", data !== null);
    if (data) {
      this.context.commit("setData", {
        displayName: data.displayName,
        email: data.email,
      });
    } else {
      this.context.commit("setData", null);
    }
  }

  getData() {
    return this.data;
  }
}

export default UserModule;
