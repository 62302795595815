import LoadingComponent from "@/components/loading/loading.component.vue";
import { cloudFunctions } from "@/main";
import { Project } from "@/models/Project";
import { httpsCallable } from "firebase/functions";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const FirestoreModule = namespace("FirestoreModule");
@Component({
  components: {
    loading: LoadingComponent,
  },
})
export default class SignContractComponent extends Vue {
  @FirestoreModule.State
  private agreement?: string;

  @FirestoreModule.State
  private projects!: Project[];

  private alreadySinged = false;
  private firstTime = false;

  private projectId?: string;
  private signContractFun = httpsCallable(cloudFunctions, "markSignedProject");
  private loading = false;
  private mainloading = true;

  mounted() {
    this.projectId = this.$route.params.id;
    const [project] = this.projects.filter(
      (project) => project.id === this.projectId
    );
    this.alreadySinged = project.signed;
    this.mainloading = false;
  }

  @Watch("projects")
  onProjectPropertyChanged() {
    const [project] = this.projects.filter(
      (project) => project.id === this.projectId
    );
    this.alreadySinged = project.signed;
    this.mainloading = false;
  }

  signContract() {
    this.loading = true;
    this.firstTime = true;
    this.signContractFun(this.projectId)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
        this.showModal();
        // window.location.replace("https://empcleaning.co.uk");
      });
  }
  showModal() {
    const model = this.$refs["my-modal"] as any;
    model.show();
  }
}
