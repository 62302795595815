var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"project"},[_c('div',{staticClass:"mb-3"},[_c('strong',[_vm._v("Client Name:")]),_vm._v(" "+_vm._s(_vm.project.clientName || "")+" ")]),_c('div',{staticClass:"mb-3"},[_c('strong',[_vm._v("Client Email:")]),_vm._v(" "+_vm._s(_vm.project.clientEmail || "")+" ")]),_c('div',{staticClass:"mb-3"},[(_vm.project.agreementContent)?_c('div',[_c('strong',[_vm._v("Contract :")]),_c('editor',{attrs:{"api-key":"1pa3t3nf4pop7wz2xu7r6as2tipg8i5zvkeqbf9cg3nnhuag","init":{
                height: 600,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              },"initial-value":_vm.project.agreementContent},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1):_c('div',[_c('strong',[_vm._v("Contract :")]),_vm._v(" Contract not available ")])]),_c('div',{staticClass:"action-buttons"},[(_vm.loading)?_c('loading'):_vm._e(),(!_vm.loading)?_c('b-button',{staticClass:"me-2",attrs:{"type":"reset","variant":"danger"},on:{"click":function($event){return _vm.gotoHome()}}},[_vm._v("Cancel")]):_vm._e(),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v("Save")]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }