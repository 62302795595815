import Vue from "vue";
import Component from "vue-class-component";
import Editor from "@tinymce/tinymce-vue";
import { namespace } from "vuex-class";
import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "@/main";
import { Watch } from "vue-property-decorator";
import LoadingComponent from "@/components/loading/loading.component.vue";
import router, { RouteNames } from "@/router";
const FirestoreModule = namespace("FirestoreModule");
@Component({
  name: "EditContract",
  components: {
    editor: Editor,
    loading: LoadingComponent,
  },
})
export default class EditContractComponent extends Vue {
  @FirestoreModule.State
  private agreement?: string;
  private loading = false;
  private content = "";
  private updateContentFn = httpsCallable(cloudFunctions, "addAgreement");

  @Watch("agreement")
  onProjectPropertyChanged() {
    this.content = this.agreement || "";
  }

  save(): void {
    this.loading = true;
    this.updateContentFn({ agreementContent: this.content })
      .then((result) => {
        console.log(result);
        this.$toast.success("Contract saved");
      })
      .catch((error) => {
        console.log(error);
        this.$toast.error("Could not save contract");
      })
      .finally(() => {
        this.loading = false;
        router.push({ name: RouteNames.HOME });
      });
  }

  gotoHome(): void {
    router.push({ name: RouteNames.HOME });
  }
}
