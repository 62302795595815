<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
  </div>
</template>

<style lang="scss"></style>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import NavBarComponent from "./components/nav-bar/nav-bar.component.vue";

@Component({
  name: "NewProject",
  components: {
    navbar: NavBarComponent,
  },
})
export default class NewProjectComponent extends Vue {}
</script>
