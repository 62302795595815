import HomeComponent from "../screens/home/home.component.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginComponent from "@/screens/login/login.component.vue";
import NewProjectComponent from "@/screens/new-project/new-project.component.vue";
import ProjectComponent from "@/screens/project/project.component.vue";
import EditContractComponent from "@/screens/edit-contract/edit-contract.component.vue";
import ReviewComponent from "@/screens/review/review.component.vue";
import SignContractComponent from "@/screens/sign-contract/sign-contract.component.vue";
import { getCurrentUser } from "@/main";
import EditProjectComponent from "@/screens/edit-project/edit-project.component.vue";
// import store from "../store";

Vue.use(VueRouter);
export enum RouteNames {
  HOME = "Home",
  LOGIN = "Login",
  NEW_PROJECT = "NewProject",
  EDIT_PROJECT = "EditProject",
  VIEW_PROJECT = "Project",
  ADD_REVIEWS = "addReviews",
  EDIT_CONTRACT = "editContract",
  SIGN_CONTRACT = "signContract",
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteNames.HOME,
    component: HomeComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: RouteNames.LOGIN,
    component: LoginComponent,
  },
  {
    path: "/new-contract",
    name: RouteNames.NEW_PROJECT,
    component: NewProjectComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contract/:id",
    name: RouteNames.VIEW_PROJECT,
    component: ProjectComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-project/:id",
    name: RouteNames.EDIT_PROJECT,
    component: EditProjectComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-contract",
    name: RouteNames.EDIT_CONTRACT,
    component: EditContractComponent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/feedback/:id",
    name: RouteNames.ADD_REVIEWS,
    component: ReviewComponent,
  },
  {
    path: "/sign-contract/:id",
    name: RouteNames.SIGN_CONTRACT,
    component: SignContractComponent,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await getCurrentUser())) {
    next(RouteNames.LOGIN);
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   console.log("logged in:", store.state.UserModule.loggedIn);
//   if (to.name !== RouteNames.LOGIN && !store.state.UserModule.loggedIn) {
//     next({ name: RouteNames.LOGIN });
//   } else if (to.name == RouteNames.LOGIN && store.state.UserModule.loggedIn) {
//     next({ name: RouteNames.HOME });
//   } else {
//     next();
//   }
// });

export default router;
