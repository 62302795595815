import Vue from "vue";
import Vuex from "vuex";
import LogRocket from "logrocket";
import UserModule from "./user-module";
import FirestoreModule from "./firestore-module";
LogRocket.init("k54dtb/emp-client-feedback");

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    UserModule,
    FirestoreModule,
  },
});
