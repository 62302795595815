import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import {
  collection,
  onSnapshot,
  query,
  limit,
  orderBy,
} from "firebase/firestore";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

// Get a Firestore instance
const firebaseConfig = {
  apiKey: "AIzaSyAJu8-UFVEM6tx-CIRa0bM_SmIzkklw9FI",
  authDomain: "emp-cleaning-booking-3ac75.firebaseapp.com",
  projectId: "emp-cleaning-booking-3ac75",
  storageBucket: "emp-cleaning-booking-3ac75.appspot.com",
  messagingSenderId: "36052950270",
  appId: "1:36052950270:web:b5c16601a25bcec102d9af",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const cloudFunctions = getFunctions(app);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
};

Vue.use(Toast, options);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

auth.onAuthStateChanged((user) => {
  console.log("user", user);
  store.dispatch("UserModule/updateData", user, { root: true });
});

const projectsCollectionRef = collection(db, "projects");
onSnapshot(projectsCollectionRef, (querySnapshot) => {
  querySnapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      store.dispatch(
        "FirestoreModule/addProject",
        { ...change.doc.data(), id: change.doc.id },
        {
          root: true,
        }
      );
    }
    if (change.type === "modified") {
      store.dispatch(
        "FirestoreModule/updateProject",
        { ...change.doc.data(), id: change.doc.id },
        {
          root: true,
        }
      );
    }
    if (change.type === "removed") {
      store.dispatch(
        "FirestoreModule/deleteProjects",
        { ...change.doc.data(), id: change.doc.id },
        {
          root: true,
        }
      );
    }
  });
});

const agreementsCollectionRef = collection(db, "agreements");
const q = query(agreementsCollectionRef, orderBy("version", "desc"), limit(1));
onSnapshot(q, (querySnapshot) => {
  querySnapshot.forEach((doc) => {
    store.dispatch(
      "FirestoreModule/updateAgreement",
      doc.data().agreementContent,
      {
        root: true,
      }
    );
  });
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
