import { Project } from "@/models/Project";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true })
class FirestoreModule extends VuexModule {
  private projects: Project[] = [];
  private agreement = "";

  @Mutation
  _addProject(project: Project) {
    this.projects.push(project);
  }
  @Mutation
  _updateProject(project: Project) {
    this.projects = this.projects.map((prjt) => {
      if (prjt.id === project.id) {
        return project;
      }
      return prjt;
    });
  }
  @Mutation
  _deleteProject(project: Project) {
    this.projects = this.projects.filter((prjt) => prjt.id !== project.id);
  }
  @Mutation
  setAgreement(agreement: string) {
    this.agreement = agreement;
  }

  @Action
  addProject(project: Project) {
    this.context.commit("_addProject", project);
  }
  @Action
  updateProject(project: Project) {
    this.context.commit("_updateProject", project);
  }
  @Action
  deleteProjects(project: Project) {
    this.context.commit("_deleteProject", project);
  }
  @Action
  updateAgreement(agreement: string) {
    this.context.commit("setAgreement", agreement);
  }
}

export default FirestoreModule;
