import { Project } from "@/models/Project";
import Vue from "vue";
import Component from "vue-class-component";
import Editor from "@tinymce/tinymce-vue";
import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "@/main";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import router, { RouteNames } from "@/router";
const FirestoreModule = namespace("FirestoreModule");

@Component({
  name: "EditProject",
  components: {
    editor: Editor,
  },
})
export default class EditProjectComponent extends Vue {
  @FirestoreModule.State
  private projects!: Project[];
  private content = "";

  project?: Project;
  loading = true;
  private updateContentFn = httpsCallable(cloudFunctions, "updateProject");

  mounted() {
    console.log("id", this.$route.params.id);
    const [prjt] = this.projects.filter(
      (project) => project.id === this.$route.params.id
    );

    if (prjt) {
      this.loading = false;
      this.project = prjt;
      this.content = prjt.agreementContent;
    }
  }

  test(){
    console.log(this.content);
  }

  update(): void {
    this.loading = true;
    this.updateContentFn({
      projectId: this.$route.params.id,
      agreementContent: this.content,
    })
      .then((result) => {
        console.log(result);
        this.$toast.success("Project updated");
      })
      .catch((error) => {
        console.log(error);
        this.$toast.error("Could not update project");
      })
      .finally(() => {
        this.loading = false;
        router.push({ name: RouteNames.HOME });
      });
  }

  gotoHome(): void {
    router.push({ name: RouteNames.HOME });
  }

  @Watch("projects")
  onProjectPropertyChanged() {
    const [prjt] = this.projects.filter(
      (project) => project.id === this.$route.params.id
    );
    this.project = prjt;
    this.content = prjt.agreementContent;
    this.loading = false;
    console.log(
      "project:",
      this.projects.filter((project) => project.id === this.$route.params.id)
    );
  }
}
