import { Project } from "@/models/Project";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const FirestoreModule = namespace("FirestoreModule");

@Component({})
export default class ProjectComponent extends Vue {
  @FirestoreModule.State
  private projects!: Project[];

  project?: Project;
  loading = true;

  mounted() {
    console.log("id", this.$route.params.id);
    const [prjt] = this.projects.filter(
      (project) => project.id === this.$route.params.id
    );

    if (prjt) {
      this.loading = false;
      this.project = prjt;
    }
  }

  @Watch("projects")
  onProjectPropertyChanged() {
    const [prjt] = this.projects.filter(
      (project) => project.id === this.$route.params.id
    );
    this.project = prjt;
    this.loading = false;
    console.log(
      "project:",
      this.projects.filter((project) => project.id === this.$route.params.id)
    );
  }
}
