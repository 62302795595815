import Vue from "vue";
import Component from "vue-class-component";
import Editor from "@tinymce/tinymce-vue";
import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "@/main";
import { namespace } from "vuex-class";
import LoadingComponent from "@/components/loading/loading.component.vue";
import router, { RouteNames } from "@/router";
const FirestoreModule = namespace("FirestoreModule");
interface FormData {
  projectName: string;
  clientEmail: string;
}

@Component({
  name: "NewProject",
  components: {
    editor: Editor,
    loading: LoadingComponent,
  },
})
export default class NewProjectComponent extends Vue {
  @FirestoreModule.State
  private agreement?: string;
  private newContract = httpsCallable(cloudFunctions, "sendAgreement");
  private form: FormData = {
    projectName: "",
    clientEmail: "",
  };

  private validations: any = {
    projectNameError: false,
    clientEmailError: false,
  };

  private staus: "PENDING" | "DONE" | "CANCELED" = "PENDING";

  private loading = false;

  validate(): boolean {
    const validCN = this.form.projectName.length > 0;
    const validCE =
      this.form.clientEmail.length > 0 &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.form.clientEmail
      );
    this.validations.projectNameError = !validCN;
    this.validations.clientEmailError = !validCE;

    return validCE && validCN;
  }

  onSubmit(event: any): void {
    event.preventDefault();
    if (this.validate()) {
      this.loading = true;
      this.newContract({
        clientName: this.form.projectName,
        clientEmail: this.form.clientEmail,
      })
        .then((result) => {
          console.log(result);
          this.$toast.success("New contract sent");
          router.push({ name: RouteNames.HOME });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Could not send contract");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  gotoHome(): void {
    router.push({ name: RouteNames.HOME });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onReset(): void {
    this.form = {
      projectName: "",
      clientEmail: "",
    };
  }
}
