var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-contract"},[_c('div',{staticStyle:{"height":"600px","position":"relative","background":"#ccc"}},[_c('h4',{staticStyle:{"position":"absolute","left":"45%","top":"50%"}},[_vm._v("Editor loading")]),_c('editor',{attrs:{"init":{
      height: 600,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
    },"initial-value":_vm.agreement},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('div',{staticClass:"action-buttons"},[(_vm.loading)?_c('loading'):_vm._e(),(!_vm.loading)?_c('b-button',{staticClass:"me-2",attrs:{"type":"reset","variant":"danger"},on:{"click":function($event){return _vm.gotoHome()}}},[_vm._v("Cancel")]):_vm._e(),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v("Save")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }