import Vue from "vue";
import Component from "vue-class-component";
import router, { RouteNames } from "@/router";
import { signOut } from "firebase/auth";
import { auth } from "@/main";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");

@Component({})
export default class NavBarComponent extends Vue {
  @UserModule.State
  public loggedIn!: boolean;
  public show = true;

  mounted(): void {
    this.show = !(
      this.$route.name == RouteNames.SIGN_CONTRACT ||
      this.$route.name == RouteNames.ADD_REVIEWS
    );
    console.log("hello from app1", this.$route.name);
  }
  gotoHome(): void {
    router.push({ name: RouteNames.HOME });
  }
  gotoProject(): void {
    router.push({ name: RouteNames.NEW_PROJECT });
  }
  gotoEditContract(): void {
    router.push({ name: RouteNames.EDIT_CONTRACT });
  }
  logout(): void {
    signOut(auth).then(() => {
      router.push({ name: RouteNames.LOGIN });
    });
  }
}
