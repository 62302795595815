import Vue from "vue";
import Component from "vue-class-component";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/main";
import { namespace } from "vuex-class";
import router, { RouteNames } from "@/router";
const UserModule = namespace("UserModule");

@Component({})
export default class LoginComponent extends Vue {
  //devtest@gmail.com
  //123456789
  private email = "";
  private password = "";
  private loginError = false;
  // private loading = false;

  @UserModule.State
  public data!: any;

  @UserModule.Action
  public updateData!: (data: any) => void;

  signIn() {
    console.log("sign in");
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((uc) => {
        const user = uc.user;
        router.push({ name: RouteNames.HOME });
        console.log(user);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
