import router, { RouteNames } from "@/router";
import Vue from "vue";
import Component from "vue-class-component";
import NavBarComponent from "../../components/nav-bar/nav-bar.component.vue";
import { namespace } from "vuex-class";
import { Project } from "@/models/Project";
import { Ref, Watch } from "vue-property-decorator";
import { ProjectStatus } from "@/constants/Constants";
import { httpsCallable } from "firebase/functions";
import { cloudFunctions } from "@/main";
const UserModule = namespace("UserModule");
const FirestoreModule = namespace("FirestoreModule");

interface Row {
  id?: string;
  clientName: string;
  clientEmail: string;
  status: string;
  loading: boolean;
}

@Component({
  components: {
    navbar: NavBarComponent,
  },
})
export default class HomeComponent extends Vue {
  @Ref("my-modal") readonly model!: any;
  // private store = useStore();
  @UserModule.State
  private loggedIn!: boolean;

  @FirestoreModule.State
  private projects!: Project[];

  private loading = false;
  private customLoading = false;

  private sendFeedbackFormFn = httpsCallable(cloudFunctions, "sendFeedback");
  private sendCustomFeedbackFormFn = httpsCallable(
    cloudFunctions,
    "sendFeedbackToNonContractClient"
  );
  private resendAgreementFn = httpsCallable(cloudFunctions, "resendAgreement");
  private deleteProjectFn = httpsCallable(cloudFunctions, "deleteProject");
  private searchByClientNameString = "";
  private searchByClientEmailString = "";
  private customFMName = "";
  private customFMNameError = "";
  private customFMEmail = "";
  private customFMEmailError = "";

  private fields = [
    { key: "clientName", label: "Client Name", tdClass: "align-middle" },
    { key: "clientEmail", label: "Client Email", tdClass: "align-middle" },
    { key: "status", label: "Status", tdClass: "align-middle" },
    { key: "actions", label: "Actions", tdClass: "align-middle" },
  ];
  private items: Row[] = [];

  showModal() {
    this.model.show();
  }
  hideModal() {
    this.model.hide();
  }
  toggleModal() {
    // We pass the ID of the button that we want to return focus to
    // when the modal has hidden
    this.model.toggle("#toggle-btn");
  }

  @Watch("projects")
  onProjectPropertyChanged() {
    this.items = this.projects?.map((project) => ({
      id: project.id,
      clientName: project.clientName,
      clientEmail: project.clientEmail,
      loading: false,
      status: this.statusEnumToString(project.projectStatus),
    }));
  }

  @Watch("searchByClientNameString")
  searchByClientName() {
    this.searchByClientEmailString = "";
    this.items = this.projects
      ?.filter((i) =>
        i.clientName
          .toLowerCase()
          .includes(this.searchByClientNameString.toLowerCase())
      )
      .map((project) => ({
        id: project.id,
        clientName: project.clientName,
        clientEmail: project.clientEmail,
        loading: false,
        status: this.statusEnumToString(project.projectStatus),
      }));
  }

  @Watch("searchByClientEmailString")
  searchByClientEmail() {
    this.searchByClientNameString = "";
    this.items = this.projects
      ?.filter((i) =>
        i.clientEmail
          .toLowerCase()
          .includes(this.searchByClientEmailString.toLowerCase())
      )
      .map((project) => ({
        id: project.id,
        clientName: project.clientName,
        loading: false,
        clientEmail: project.clientEmail,
        status: this.statusEnumToString(project.projectStatus),
      }));
  }

  mounted() {
    if (!this.loggedIn) {
      router.push({ name: RouteNames.LOGIN });
    }
    this.items = this.projects?.map((project) => ({
      id: project.id,
      clientName: project.clientName,
      loading: false,
      clientEmail: project.clientEmail,
      status: this.statusEnumToString(project.projectStatus),
    }));
  }

  gotoProjectPage(id: string) {
    if (id) {
      router.push({ name: RouteNames.VIEW_PROJECT, params: { id: id } });
    } else {
      console.log("error");
    }
  }

  gotoEditProjectPage(id: string) {
    if (id) {
      router.push({ name: RouteNames.EDIT_PROJECT, params: { id: id } });
    } else {
      console.log("error");
    }
  }

  sendFeedbackForm(projectId: string) {
    const [p] = this.items.filter((p) => p.id === projectId);
    if (p && !p.loading) {
      p.loading = true;

      this.sendFeedbackFormFn({ projectId })
        .then((res) => {
          console.log("res", res);
          this.$toast.success("Feedback sent successfully");
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast.error("Feedback failed to send");
        })
        .finally(() => {
          p.loading = false;
        });
    }
  }
  resendAgreement(projectId: string) {
    const [p] = this.items.filter((p) => p.id === projectId);
    if (p && !p.loading) {
      p.loading = true;

      this.resendAgreementFn({ projectId })
        .then((res) => {
          console.log("res", res);
          this.$toast.success("Resend Agreement successfully");
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast.error("Agreement failed to resend");
        })
        .finally(() => {
          p.loading = false;
        });
    }
  }
  sendCustomFeedbackForm() {
    this.customFMEmailError = "";
    this.customFMNameError = "";
    this.customLoading = true;

    if (this.validateForm()) {
      console.log(this.customFMName, this.customFMEmail);

      this.sendCustomFeedbackFormFn({
        client: {
          name: this.customFMName,
          email: this.customFMEmail,
        },
      })
        .then((res) => {
          console.log("res", res);
          this.$toast.success("Feedback sent successfully");
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast.error("Feedback failed to send");
        })
        .finally(() => {
          this.customLoading = false;
        });
    }
  }

  validateForm() {
    let b1 = true;
    let b2 = true;
    if (this.customFMName.length < 1) {
      b1 = false;
      this.customFMNameError = "Please enter a valid name";
    }
    if (!this.validateEmail(this.customFMEmail)) {
      b2 = false;
      this.customFMEmailError = "Please enter a valid email address";
    }
    return b1 && b2;
  }

  validateEmail(email: string) {
    const regexp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regexp.test(email);
  }

  statusEnumToString(value: ProjectStatus) {
    switch (value) {
      case ProjectStatus.FEEDBACK_SUBMITTED:
        return "Feedback Submitted";
      case ProjectStatus.AGREED:
        return "Agreed";
      case ProjectStatus.SENT_AGREEMENT:
        return "Sent Agreement";
      default:
        return "Sent Feedback";
    }
  }

  deleteProject(projectId: string) {
    const [p] = this.items.filter((p) => p.id === projectId);
    if (p && !p.loading) {
      p.loading = true;
      this.deleteProjectFn(projectId)
        .then((res) => {
          console.log(res);
          this.$toast.success("Client is Deleted");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Client Delete Failed");
        })
        .finally(() => {
          p.loading = false;
        });
    }
  }
}
