import LoadingComponent from "@/components/loading/loading.component.vue";
import { ProjectStatus } from "@/constants/Constants";
import { cloudFunctions } from "@/main";
import { Project } from "@/models/Project";
import { httpsCallable } from "firebase/functions";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import StarRating from "vue-star-rating";
import { namespace } from "vuex-class";
const FirestoreModule = namespace("FirestoreModule");

interface FormField {
  name: string;
  value: number | string;
}

interface Form {
  fields: FormField[];
}
@Component({
  components: {
    StarRating,
    loading: LoadingComponent,
  },
})
export default class ReviewComponent extends Vue {
  @FirestoreModule.State
  private projects!: Project[];

  form: Form = {
    fields: [
      {
        name: "01. Company/Org name:",
        value: "",
      },
      {
        name: "02 .Did we meet your expectation?",
        value: 0,
      },
      {
        name: "03 .How would you rate your interaction with our employee?",
        value: 0,
      },
      {
        name: "04 .How would you rate our proficiency?",
        value: 0,
      },
      {
        name: "05 .How would you rate our punctuality?",
        value: 0,
      },
      {
        name: "06 .How would you rate our customer services/complain/query handling?",
        value: 0,
      },
      {
        name: "07 .How would you rate our prices?",
        value: 0,
      },
      {
        name: "08 .Would you like to repeat business with us?",
        value: 0,
      },
      {
        name: "09. If anything, else we can put right?",
        value: "",
      },
      {
        name: "10. Any suggestions?",
        value: "",
      },
    ],
  };

  private submitFeedbackFun = httpsCallable(
    cloudFunctions,
    "markFeedbackAnsweredProject"
  );

  loading = false;
  mainloading = true;
  projectId = this.$route.params.id;

  alreadySubmitted = false;
  firstTime = false;

  mounted() {
    this.projectId = this.$route.params.id;
    const [project] = this.projects.filter(
      (project) => project.id === this.projectId
    );
    this.alreadySubmitted =
      project.projectStatus === ProjectStatus.FEEDBACK_SUBMITTED;
    this.mainloading = false;
  }
  @Watch("projects")
  onProjectPropertyChanged() {
    const [project] = this.projects.filter(
      (project) => project.id === this.projectId
    );
    this.alreadySubmitted =
      project.projectStatus === ProjectStatus.FEEDBACK_SUBMITTED;
    this.mainloading = false;
  }

  submit() {
    this.firstTime = true;
    this.loading = true;
    this.submitFeedbackFun({
      projectId: this.$route.params.id,
      feedbackQuestions: this.form.fields,
    })
      .then((res) => {
        console.log(res);
        this.$toast.success(" You’ve successfully submitted your feedback");
      })
      .catch((err) => {
        console.log(err);
        this.$toast.error("Feedback sumbmition failed");
      })
      .finally(() => {
        console.log("done");
        this.loading = false;
        // window.location.replace("https://empcleaning.co.uk");
      });
  }
}
